import React from 'react'
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'

function ikWilEenSnelleWebsite() {
  return (
    <Layout>
      <Helmet>
        <title>Ik wil een snelle website - Website Laten Stylen</title>
        <meta name="description" content="Wil je ook geen trage website meer? Mijn websites zijn supersnel, ook op een langzame server!" />
        <meta property="og:description" content="Wil je ook geen trage website meer? Mijn websites zijn supersnel, ook op een langzame server!" />
      </Helmet>
      <div className='container'>
        <h1>Ik wil een snelle website</h1>
        <p>Voor simpele websites zoals deze heeft u geen Content Management Systeem (CMS) nodig. Of wellicht wel maar dan in de vorm van een headless CMS. Dit houdt in dat u de content via het CMS kunt vullen, zoals u gewend bent. De voorkant van uw website wordt echter niet meer verzorgt door uw CMS maar door React: een Javascript bibliotheek waar websites als Facebook, Airbnb, Netflix en Instagram gebruik van maken. De meeste CMS systemen zijn vrij gemakkelijk te koppelen.</p>
        <h2>Waarom React?</h2>
        <p>React heeft diverse voordelen:</p>
        <p>
          <ul>
            <li>Het voordeel van React is dat niet complete webpagina's vernieuwd worden als u naar een andere pagina gaat. Maar alleen de onderdelen die gewijzigd zijn (door gebruik van de virtual DOM).</li>
            <li>React is geen framework zoals veel mede ontwikkelaars beweren maar een library. Het grote verschil is dat je met een library alleen de onderdelen gebruikt die je voor de website of applicatie nodig hebt. Simpele applicaties of websites zijn dus relatief klein.</li>
            <li>React is modulair (door de opsplitsing in componenten) wat resulteert in schone en leesbare code.</li>
            <li>SEO! React is niet alleen megasnel wat resulteert in uitzonderlijk hoge PageSpeed scores maar een React applicatie of website is ook nog eens prima te optimaliseren op SEO gebied.</li>
            <li>Enigszins inherent aan bovenstaand punt: door gebruik te maken van slimme componenten worden afbeeldingen automatisch geoptimaliseerd en pagina's al onder water geladen als u slechts met uw muis over een link beweegt.</li>
          </ul>
          <p>Nieuwsgierig of React iets voor u is? We kunnen altijd uw wens bespreken en zien of er een mooie oplossing te verzinnen is. Geheel vrijblijvend natuurlijk!</p>
          <p><Link to="/contact/" className='button'>Neem contact op</Link></p>
        </p>
      </div>
    </Layout>
  )
}

export default ikWilEenSnelleWebsite